<template>
  <div>
    <v-card class="pa-3" outlined>
      <v-stepper v-model="step" class="elevation-0">
        <v-stepper-items>
          <!-- STEP 1 -->
          <v-stepper-content step="1" class="pa-0">
            <div v-if="dataMood" class="pa-3">
              <h3 class="text-center">Bagaimana kabarmu hari ini?</h3>
              <div class="d-flex justify-center mt-3">
                <div style="width: 50%" class="mb-3">
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="(item, i) in dataMood.default_mood"
                      :key="`mood-${i}`"
                    >
                      <v-card
                        :color="
                          item.id == 1
                            ? '#ffb221'
                            : item.id == 2
                            ? '#3fbfad'
                            : item.id == 3
                            ? '#8f93cd'
                            : item.id == 4
                            ? '#8d98a5'
                            : '#677187'
                        "
                        height="300px"
                        flat
                      >
                        <!-- <v-card-text>{{ item.name }}</v-card-text> -->
                        <div
                          class="d-flex align-center justify-center"
                          style="height: 100%"
                        >
                          <div class="d-flex flex-column align-center">
                            <h2 class="text-center mb-7">
                              {{ item.name }}
                            </h2>
                            <img :src="item.logo" alt="" height="100px" />
                            <p class="text-center">
                              {{ $date().format("MMMM, DD") }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </div>

              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
                hide-slider
              >
                <v-tab
                  v-for="(item, i) in dataMood.default_mood"
                  :key="`moodhead-${i}`"
                  :active-class="`active_${item.id}`"
                  @click="myMood.mood = item.id"
                >
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </div>
          </v-stepper-content>

          <!-- STEP 2 -->
          <v-stepper-content step="2" class="pa-0">
            <h3 class="text-center mb-3">Bagaimana perasaanmu?</h3>
            <div class="d-flex justify-center" v-if="dataFeel">
              <div style="width: 50%" class="mb-3">
                <div class="d-flex justify-center flex-wrap">
                  <div
                    v-for="(item, i) in dataFeel.default_feel"
                    :key="`feel-${i}`"
                  >
                    <v-btn
                      class="ma-2"
                      :color="myMood.feel == item.id ? '#3b053c' : ''"
                      :dark="myMood.feel == item.id"
                      depressed
                      @click="myMood.feel = item.id"
                    >
                      {{ item.name }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-stepper-content>
          <!-- STEP 3 -->
          <v-stepper-content step="3" class="pa-3">
            <h3 class="text-center mb-3">Apa yang kamu lakukan?</h3>
            <div class="d-flex justify-center" v-if="dataDo">
              <div style="width: 60%" class="mb-3">
                <div class="d-flex justify-center flex-wrap">
                  <div
                    v-for="(item, i) in dataDo.default_doing"
                    :key="`feel-${i}`"
                  >
                    <v-btn
                      class="ma-2"
                      :color="myMood.do == item.id ? '#3b053c' : ''"
                      :dark="myMood.do == item.id"
                      depressed
                      @click="myMood.do = item.id"
                    >
                      {{ item.name }}
                    </v-btn>
                  </div>
                </div>
                <div
                  class="d-flex justify-center flex-wrap"
                  v-if="dataDo.my_doing.length"
                >
                  <div v-for="(item, i) in dataDo.my_doing" :key="`feel-${i}`">
                    <div class="ma-2">
                      <v-btn
                        :color="myMood.do == item.id ? '#3b053c' : ''"
                        :dark="myMood.do == item.id"
                        depressed
                        @click="myMood.do = item.id"
                      >
                        {{ item.name }}
                      </v-btn>
                      <v-btn
                        icon
                        class="config_cog"
                        depressed
                        x-small
                        dark
                        color="blue"
                        @click="doConf(item)"
                      >
                        <v-icon small>mdi-cog</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-center">
              <div>
                <v-btn
                  depressed
                  color="blue"
                  v-if="!wannaadd"
                  dark
                  @click="showField"
                  >+ add do</v-btn
                >
                <div class="d-flex align-center pb-3" v-if="wannaadd">
                  <v-text-field
                    hide-details="auto"
                    @keyup.enter="addDo"
                    v-model="fieldDo"
                    :loading="addingDo"
                    ref="typist"
                    hint="tekan Enter untuk menyimpan"
                    dense
                    placeholder="Apa yang kamu lakukan?"
                    color="#00b4cc"
                  ></v-text-field>
                  <div class="d-flex justify-end">
                    <v-btn icon small @click="addDo"
                      ><v-icon>mdi-check</v-icon></v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-stepper-content>
          <!-- STEP 4 -->
          <v-stepper-content step="4" class="pa-0">
            <h3 class="text-center mb-3">Dengan Siapa kamu?</h3>
            <div class="d-flex justify-center" v-if="dataWith">
              <div style="width: 50%" class="mb-3">
                <div class="d-flex justify-center flex-wrap">
                  <div
                    v-for="(item, i) in dataWith.default_with"
                    :key="`feel-${i}`"
                  >
                    <v-btn
                      class="ma-2"
                      :color="myMood.with == item.id ? '#3b053c' : ''"
                      :dark="myMood.with == item.id"
                      depressed
                      @click="myMood.with = item.id"
                    >
                      {{ item.name }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-stepper-content>
          <!-- STEP 5 -->
          <v-stepper-content step="5" class="pa-0">
            <h3 class="text-center mb-3">Dimana Kamu?</h3>
            <div class="d-flex justify-center" v-if="dataWhere">
              <div style="width: 50%" class="mb-3">
                <div class="d-flex justify-center flex-wrap">
                  <div
                    v-for="(item, i) in dataWhere.default_where"
                    :key="`feel-${i}`"
                  >
                    <v-btn
                      class="ma-2"
                      :color="myMood.where == item.id ? '#3b053c' : ''"
                      :dark="myMood.where == item.id"
                      depressed
                      @click="myMood.where = item.id"
                    >
                      {{ item.name }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <div class="d-flex justify-space-between mt-5">
        <div>
          <v-btn v-if="step != 1" depressed dark color="purple" @click="step--"
            >Back</v-btn
          >
        </div>
        <div v-if="step == 1">
          <v-btn depressed dark color="purple" @click="step++">Next</v-btn>
        </div>
        <div v-if="step == 2">
          <v-btn
            v-if="myMood.feel"
            depressed
            dark
            color="purple"
            @click="step++"
            >Next</v-btn
          >
        </div>
        <div v-if="step == 3">
          <v-btn v-if="myMood.do" depressed dark color="purple" @click="step++"
            >Next</v-btn
          >
        </div>
        <div v-if="step == 4">
          <v-btn
            v-if="myMood.with"
            depressed
            dark
            color="purple"
            @click="step++"
            >Next</v-btn
          >
        </div>
        <div v-if="step == 5">
          <v-btn
            v-if="myMood.where"
            depressed
            dark
            color="purple"
            @click="saveMood"
            >Save</v-btn
          >
        </div>
      </div>
      <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
        <v-progress-circular
          indeterminate
          class="mr-2"
          color="purple"
        ></v-progress-circular>
        <b class="purple--text">Loading...</b>
      </v-overlay>
    </v-card>
    <div class="pa-2">
      <config-do
        :d_config="d_config"
        @refetch="getDo"
        @close="d_config = false"
        :selected="selected"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ConfigDo from "./configDo.vue";
export default {
  name: "createMood",
  components: { ConfigDo },
  data() {
    return {
      loading: false,
      step: 1,
      tab: "tab-1",
      dataMood: null,
      dataFeel: null,
      dataDo: null,
      dataWhere: null,
      dataWith: null,
      wannaadd: false,
      addingDo: false,
      d_config: false,
      selected: null,
      fieldDo: "",
      myMood: {
        mood: null,
        feel: null,
        do: null,
        where: null,
        with: null
      }
    };
  },
  mounted() {
    this.triggerFetch();
  },
  methods: {
    showField() {
      this.wannaadd = true;
      setTimeout(() => {
        this.$refs["typist"].$refs.input.focus();
      }, 100);
    },
    addDo() {
      if (this.fieldDo != "") {
        this.addingDo = true;
        this.$store
          .dispatch("moodtracker/createDo", { name: this.fieldDo })
          .then(data => {
            this.getDo();
            Swal.fire({
              title: data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            });
            this.addingDo = false;
            this.wannaadd = false;
            this.fieldDo = "";
          });
      }
    },
    doConf(item) {
      this.selected = item;
      this.d_config = true;
    },
    triggerFetch() {
      this.getMood();
      this.getFeel();
      this.getDo();
      this.getWhere();
      this.getWith();
    },
    getMood() {
      this.loading = true;
      this.$store.dispatch("moodtracker/getMood").then(data => {
        this.dataMood = data;
        this.loading = false;
      });
    },

    getFeel() {
      this.loading = true;
      this.$store.dispatch("moodtracker/getFeel").then(data => {
        this.dataFeel = data;
        this.loading = false;
      });
    },

    getDo() {
      this.loading = true;
      this.$store.dispatch("moodtracker/getDoing").then(data => {
        this.dataDo = data;
        this.loading = false;
      });
    },

    getWhere() {
      this.loading = true;
      this.$store.dispatch("moodtracker/getWhere").then(data => {
        this.dataWhere = data;
        this.loading = false;
      });
    },

    getWith() {
      this.loading = true;
      this.$store.dispatch("moodtracker/getWith").then(data => {
        this.dataWith = data;
        this.loading = false;
      });
    },

    saveMood() {
      this.loading = true;
      let data = {
        mood_id: this.myMood.mood,
        feel_id: this.myMood.feel,
        doing_id: this.myMood.do,
        with_id: this.myMood.with,
        where_id: this.myMood.where,
        note: "-"
      };
      this.$store.dispatch("moodtracker/saveMoodToday", data).then(data => {
        console.log(data);
        this.myMood = {
          mood: null,
          feel: null,
          do: null,
          where: null,
          with: null
        };
        this.step = 1;
        this.tab = "tab-1";
        Swal.fire({
          title: data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          position: "top",
          toast: true
        });
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.active_1 {
  border-radius: 10px;
  background: #ffb221;
}
.active_2 {
  border-radius: 10px;
  background: #3fbfad;
}
.active_3 {
  border-radius: 10px;
  background: #8f93cd;
}
.active_4 {
  border-radius: 10px;
  background: #8d98a5;
}
.active_5 {
  border-radius: 10px;
  background: #677187;
}
.action_btn {
  margin-top: -30px;
}
.config_cog {
  margin-left: -10px;
  margin-top: -30px;
}
</style>
