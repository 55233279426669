<template>
  <div class="_bg-default _80w _100vh px-5 py-3">
    <div class="_100top">
      <v-card class="pa-3 radius-card">
        <create-mood class="mb-5" />
        <chart-mood class="mb-5" />
        <history-mood />
      </v-card>
    </div>
  </div>
</template>

<script>
import ChartMood from "./chartMood.vue";
import CreateMood from "./createMood.vue";
import HistoryMood from "./historyMood.vue";
export default {
  name: "moodtracker",
  components: { CreateMood, ChartMood, HistoryMood },
  data() {
    return {
      step: 1
    };
  }
};
</script>

<style></style>
