<template>
  <div>
    <v-card class="pa-3" outlined>
      <div class="pa-3 d-flex align-center">
        <h3>Mood anda</h3>
        <div class="mx-3" style="width: 150px">
          <v-select
            :items="time"
            hide-details
            item-text="txt"
            item-value="val"
            v-model="selected"
            @change="getHistory"
            dense
            outlined
          ></v-select>
        </div>
        <h3>terakhir</h3>
      </div>
      <div id="chart" v-if="config">
        <apexchart
          type="line"
          height="350"
          :options="config.options"
          :series="config.series"
        ></apexchart>
      </div>
      <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
        <v-progress-circular
          indeterminate
          class="mr-2"
          color="purple"
        ></v-progress-circular>
        <b class="purple--text">Loading...</b>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "chartMood",
  data() {
    return {
      loading: false,
      step: 1,
      tab: "tab-1",
      dataMood: null,
      config: null,
      time: [
        {
          txt: "24 jam",
          val: 1
        },
        {
          txt: "1 minggu",
          val: 7
        },
        {
          txt: "1 bulan",
          val: 30
        }
      ],
      selected: 30
    };
  },

  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      let data = {
        time: this.selected,
        page: 1,
        limit: this.selected
      };
      this.$store.dispatch("moodtracker/getHistory", data).then(data => {
        console.log(data);
        this.dataMood = data.chart.data;
        let list = data.chart.data;
        let arr = {
          mood: [],
          feel: [],
          do: [],
          with: [],
          where: [],
          label: []
        };
        list.forEach(el => {
          arr.mood.push(el.mood_id);
          arr.feel.push(el.feel_id);
          arr.do.push(el.doing_id);
          arr.with.push(el.with_id);
          arr.where.push(el.where_id);
          arr.label.push(el.date);
        });
        let series = [
          {
            name: "mood",
            data: arr.mood
          },
          {
            name: "feel",
            data: arr.feel
          },
          {
            name: "do",
            data: arr.do
          },
          {
            name: "with",
            data: arr.with
          },
          {
            name: "where",
            data: arr.where
          }
        ];

        let dataChart = {
          series: series,
          label: arr.label
        };
        this.loading = false;
        this.createChart(dataChart);
      });
    },
    createChart(item) {
      let options = {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: item.label
        }
      };
      this.config = {
        options: options,
        series: item.series
      };
    }
  }
};
</script>

<style scoped>
.active_1 {
  border-radius: 10px;
  background: #ffb221;
}
.active_2 {
  border-radius: 10px;
  background: #3fbfad;
}
.active_3 {
  border-radius: 10px;
  background: #8f93cd;
}
.active_4 {
  border-radius: 10px;
  background: #8d98a5;
}
.active_5 {
  border-radius: 10px;
  background: #677187;
}
</style>
