<template>
  <v-row justify="start">
    <v-dialog v-model="d_detail" persistent scrollable max-width="500px">
      <v-card class="radius-card hide_overflow">
        <v-card-text class="px-0" v-if="unit">
          <v-container class="pa-0">
            <v-card
              flat
              :color="
                unit.all.mood_id == 1
                  ? '#ffb221'
                  : unit.all.mood_id == 2
                  ? '#3fbfad'
                  : unit.all.mood_id == 3
                  ? '#8f93cd'
                  : unit.all.mood_id == 4
                  ? '#8d98a5'
                  : '#677187'
              "
              height="200px"
            >
              <v-toolbar
                dense
                :color="
                  unit.all.mood_id == 1
                    ? '#ffb221'
                    : unit.all.mood_id == 2
                    ? '#3fbfad'
                    : unit.all.mood_id == 3
                    ? '#8f93cd'
                    : unit.all.mood_id == 4
                    ? '#8d98a5'
                    : '#677187'
                "
                flat
              >
                <v-spacer></v-spacer>
                <v-btn fab x-small depressed @click="$emit('close')"
                  ><v-icon small>mdi-close</v-icon></v-btn
                >
              </v-toolbar>
              <div>
                <div
                  class="d-flex align-center justify-center"
                  style="height: 100%"
                >
                  <div>
                    <h2 class="text-center">
                      {{ unit.all.mood.name }}
                    </h2>
                    <div class="d-flex justify-center">
                      <v-icon
                        class="text-center my-3"
                        v-if="unit.all.mood_id == 1"
                        color="#000"
                        x-large
                        >mdi-emoticon-cool-outline</v-icon
                      >
                      <v-icon
                        class="text-center my-3"
                        v-if="unit.all.mood_id == 2"
                        color="#000"
                        x-large
                        >mdi-emoticon-excited-outline</v-icon
                      >
                      <v-icon
                        class="text-center my-3"
                        v-if="unit.all.mood_id == 3"
                        color="#000"
                        x-large
                        >mdi-emoticon-neutral-outline</v-icon
                      >
                      <v-icon
                        class="text-center my-3"
                        v-if="unit.all.mood_id == 4"
                        color="#000"
                        x-large
                        >mdi-emoticon-cry-outline</v-icon
                      >
                      <v-icon
                        class="text-center my-3"
                        v-if="unit.all.mood_id == 5"
                        color="#000"
                        x-large
                        >mdi-emoticon-poop-outline</v-icon
                      >
                    </div>
                    <p class="text-center">
                      {{ $date(unit.all.date).format("MMMM, DD") }}
                    </p>
                  </div>
                </div>
              </div>
            </v-card>
            <div>
              <div class="temp_chart">
                <apexchart
                  height="250"
                  type="pie"
                  :options="unit.options"
                  :series="unit.series"
                ></apexchart>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "detailMood",
  props: ["d_detail", "unit"],

  data() {
    return {
      role: "",
      loading: false,
      picked: null
    };
  },
  methods: {}
};
</script>

<style scoped>
.active_1 {
  border-radius: 10px;
  background: #ffb221;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
.active_2 {
  border-radius: 10px;
  background: #3fbfad;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
.active_3 {
  border-radius: 10px;
  background: #8f93cd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
.active_4 {
  border-radius: 10px;
  background: #8d98a5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
.active_5 {
  border-radius: 10px;
  background: #677187;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
.temp_chart {
  width: 100%;
  height: 250px;
}
</style>
