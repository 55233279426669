<template>
  <v-row justify="start">
    <v-dialog v-model="d_config" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end px-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          class="d-flex flex-column align-center justify-center"
          style="height: 230px"
        >
          <div>
            <div class="d-flex justify-center" v-if="!picked">
              <v-btn
                @click="picked = 1"
                class="text-capitalize py-6 mr-4"
                dark
                color="purple"
                width="100px"
                depressed
                >Update</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="picked = 2"
                outlined
                color="purple"
                width="100px"
                depressed
                >Delete</v-btn
              >
            </div>
          </div>
          <div v-if="selected && picked == 1">
            <div class="d-flex justify-center">
              <v-text-field
                hide-details="auto"
                @keyup.enter="updateItem"
                v-model="selected.name"
                ref="typist"
                dense
                placeholder="Apa yang kamu lakukan?"
                color="#00b4cc"
              ></v-text-field>
            </div>
            <div class="d-flex justify-center mt-2">
              <v-btn
                @click="updateItem"
                class="text-capitalize py-6 mr-4"
                dark
                color="purple"
                width="100px"
                depressed
                >Update</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="picked = null"
                outlined
                color="purple"
                width="100px"
                depressed
                >Cancel</v-btn
              >
            </div>
          </div>
          <div v-if="picked == 2">
            <div class="px-5">
              <p class="small_txt color_txt text-center">
                Yakin ingin menghapus item?
              </p>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                @click="deleteItem"
                class="text-capitalize py-6 mr-4"
                dark
                color="purple"
                width="100px"
                depressed
                >Delete</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="picked = null"
                outlined
                color="purple"
                width="100px"
                depressed
                >Cancel</v-btn
              >
            </div>
          </div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="purple"
            ></v-progress-circular>
            <b class="purple--text">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "d_config",
  props: ["d_config", "selected"],

  data() {
    return {
      role: "",
      loading: false,
      picked: null
    };
  },
  methods: {
    // dmaking(item) {

    // },
    updateItem() {
      this.loading = true;
      let data = {
        id: this.selected.id,
        name: this.selected.name
      };
      this.$store
        .dispatch("moodtracker/updateDo", data)
        .then(data => {
          console.log(data);
          this.picked = null;
          this.$emit("close");
          this.$emit("refetch");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteItem() {
      this.loading = true;
      let data = {
        id: this.selected.id
      };
      this.$store
        .dispatch("moodtracker/deleteDo", data)
        .then(data => {
          console.log(data);
          this.$emit("close");
          this.picked = null;
          this.$emit("refetch");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
