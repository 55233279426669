<template>
  <div>
    <v-card class="pa-3" outlined>
      <div class="pa-3 d-flex align-center">
        <h3>history mood</h3>
        <div class="mx-3" style="width: 150px">
          <v-select
            :items="time"
            hide-details
            item-text="txt"
            item-value="val"
            v-model="selected"
            @change="getHistory"
            dense
            outlined
          ></v-select>
        </div>
        <h3>terakhir</h3>
      </div>
      <div v-if="dataMood">
        <v-card
          v-for="(item, i) in dataMood"
          outlined
          :key="i"
          class="pa-2 mb-3"
        >
          <v-row>
            <v-col cols="2">
              <p class="ma-0 small_txt color_txt">moodku</p>
              <b class="b_small">{{ item.mood.name }}</b>
            </v-col>
            <v-col cols="2">
              <p class="ma-0 small_txt color_txt">aku merasa</p>
              <b class="b_small">{{ item.feel.name }}</b>
            </v-col>
            <v-col cols="2">
              <p class="ma-0 small_txt color_txt">saat sedang</p>
              <b class="b_small">{{ item.doing.name }}</b>
            </v-col>
            <v-col cols="2">
              <p class="ma-0 small_txt color_txt">dengan</p>
              <b class="b_small">{{ item.withs.name }}</b>
            </v-col>
            <v-col cols="2">
              <p class="ma-0 small_txt color_txt">di</p>
              <b class="b_small">{{ item.wheres.name }}</b>
            </v-col>
            <v-col cols="2">
              <div
                class="d-flex align-center justify-center"
                style="height: 100%"
              >
                <v-btn color="blue" @click="detilMood(item)" dark depressed
                  >Detil</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card>

        <div class="d-flex justify-center">
          <v-card outlined class="pa-2 d-flex align-center">
            <v-btn
              small
              depressed
              dark
              color="blue"
              @click="pagination('prev')"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <div class="mx-2">{{ page }} of {{ last_page }}</div>
            <v-btn
              small
              depressed
              dark
              color="blue"
              @click="pagination('next')"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card>
        </div>
      </div>
      <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
        <v-progress-circular
          indeterminate
          class="mr-2"
          color="purple"
        ></v-progress-circular>
        <b class="purple--text">Loading...</b>
      </v-overlay>
    </v-card>
    <div class="pa-2">
      <detail-mood
        :d_detail="d_detail"
        :unit="unit"
        @close="d_detail = false"
      />
    </div>
  </div>
</template>

<script>
import detailMood from "./detailMood.vue";
export default {
  components: { detailMood },
  name: "historyMood",
  data() {
    return {
      loading: false,
      step: 1,
      tab: "tab-1",
      dataMood: null,
      descMood: null,
      config: null,
      time: [
        {
          txt: "24 jam",
          val: 1
        },
        {
          txt: "1 minggu",
          val: 7
        },
        {
          txt: "1 bulan",
          val: 30
        }
      ],
      selected: 30,
      page: 1,
      limit: 5,
      last_page: null,
      unit: null,
      d_detail: false
    };
  },

  mounted() {
    this.getHistory();
  },
  methods: {
    pagination(state) {
      if (state == "next") {
        if (this.page < this.last_page) {
          this.page++;
          this.getHistory();
        }
      } else {
        if (this.page > 1) {
          this.page--;
          this.getHistory();
        }
      }
    },
    getHistory() {
      let data = {
        time: this.selected,
        page: this.page,
        limit: this.limit
      };
      this.$store.dispatch("moodtracker/getHistory", data).then(data => {
        this.last_page = data.chart.last_page;
        this.dataMood = data.chart.data;

        this.loading = false;
      });
    },
    detilMood(item) {
      let series = [
        item.mood_id,
        item.feel_id,
        item.doing_id,
        item.with_id,
        item.where_id
      ];
      let label = [
        item.mood.name,
        item.feel.name,
        item.doing.name,
        item.withs.name,
        item.wheres.name
      ];
      let options = {
        chart: {
          width: 380,
          type: "pie"
        },
        labels: label,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };
      this.unit = {
        all: item,
        options: options,
        series: series
      };
      this.d_detail = true;
    }
  }
};
</script>

<style scoped>
.active_1 {
  border-radius: 10px;
  background: #ffb221;
}
.active_2 {
  border-radius: 10px;
  background: #3fbfad;
}
.active_3 {
  border-radius: 10px;
  background: #8f93cd;
}
.active_4 {
  border-radius: 10px;
  background: #8d98a5;
}
.active_5 {
  border-radius: 10px;
  background: #677187;
}
.b_small {
  font-size: 15px;
}
</style>
